import React from 'react'

import { MainWrapper, CupImage } from './main.styles'
import Cup from '../../assets/cup.png'
import SectionHeading from '../../components/SectionHeading/section-heading.component'
import { SectionAwardStart, SectionAwardStartManagers} from '../../components/SectionAwardsStart/section-award-start.component'
import Nominations from '../../components/Nominations/nominations.component'

const Main = () => {

   return (
      <MainWrapper>
         {/* <button onClick={() => setModalOpen(true)} >Press me</button> */}
         {/* <InstructionsModal open={isModalOpen} onClose={()=>setModalOpen(false)} /> */}
         <CupImage src={Cup} />
         <SectionHeading />
         {/* <Nominations /> */}
          <SectionAwardStartManagers />
         <SectionAwardStart />

      </MainWrapper>
   )
}

export default Main;