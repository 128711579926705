import { createStore, combineReducers, applyMiddleware } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1'
import thunk from 'redux-thunk'
import logger from 'redux-logger'

import { userReducer } from './user/user.reducer'
import { modalsReducer } from './modals/modals.reducer'


const middlewares = [thunk]

// if (process.env.NODE_ENV!=='production') {
//    middlewares.push(logger)
// }

const persistConfig = {
   key: 'root',
   storage: storage,
   stateReconciler: autoMergeLevel1,
   blacklist: []
}

const reducers = combineReducers({
   user: userReducer,
   modals: modalsReducer
})

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = createStore(persistedReducer, applyMiddleware(...middlewares))

export const persistor = persistStore(store)