import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import {
   HeaderWrapper,
   Logo,
   NavWrapper,
   ContactsWrapper,
   LinkText,
   ExitButton,
   UserName,
   MenuIcon,
   LoggedName,
   NavContentWrapper
} from './header-mobile.styles'
import logoSVG from '../../assets/logo.svg'
import { userSelectors } from '../../redux/user/user.selectors';
import { logout } from '../../redux/user/user.actions';
import { ReactComponent as BurgerMenuSVG } from '../../assets/burger-menu.svg';

const HeaderMobile = () => {
   const currentUser = useSelector(userSelectors.currentUser)
   const dispatch = useDispatch()
   const [isMenuOpen, setMenuOpen] = useState(false)
   const menuRef = useRef()

   useEffect(() => {
      const listener = event => {
         if (!menuRef.current || menuRef.current.contains(event.target)) {
            return;
         }
         setMenuOpen(false);
      };
      document.addEventListener('mousedown', listener);
      return () => {
         document.removeEventListener('mousedown', listener);
      };
   }, [menuRef]);


   return (
      <HeaderWrapper ref={menuRef}>
         <Logo src={logoSVG} alt='logo' />
         <MenuIcon onClick={() => setMenuOpen(!isMenuOpen)}>
            <BurgerMenuSVG />
         </MenuIcon>
         <NavWrapper user={currentUser !== undefined} open={isMenuOpen} >
            {
               currentUser &&
               <LoggedName>
                  <UserName>
                      <p>Вы авторизованы</p>
                      <p>{` ${currentUser.user_info.email}`}</p>
                     {/*{`Вы авторизованы как ${currentUser.user_info.first_name} ${currentUser.user_info.last_name}`}*/}
                  </UserName>
                  <ExitButton onClick={() => dispatch(logout())}>
                     <LinkText exit>
                        Выход
                     </LinkText>
                  </ExitButton>
               </LoggedName>
            }
            <ContactsWrapper>
               <a href='mailto:support@2024vote-online.ru'>
                  <LinkText>
                     support@2024vote-online.ru
               </LinkText>
               </a>
               <a href='tel:+79995357879'>
                  <LinkText>
                     +79995357879
               </LinkText>
               </a>
            </ContactsWrapper>
         </NavWrapper>
      </HeaderWrapper >
   )
}

export default HeaderMobile