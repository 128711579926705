import styled from 'styled-components'

export const HeaderWrapper = styled.header`
   width: 100%;
   height: 60px;
   // padding: 0 24px;
   background: #215379;
   box-shadow: 0px 8px 12px rgba(3, 68, 107, 0.15);

   display: flex;
   align-items: center;
   justify-content: flex-end;
   position: fixed;
   z-index: 100;
   transition: all .6s ease;
   
`

export const Logo = styled.img`
   position: absolute;
   left: 24px;
`

export const MenuIcon = styled.button`
    outline: none;
    background-color: transparent;
    border: 0;
    transition-duration: 0.3s;
    margin-right: 20px;

    :hover{
        cursor: pointer;
        >svg>path{
            stroke-opacity: 0.6;
            transition-duration: 0.3s;
        }
        
    }
`

export const NavWrapper = styled.nav`
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    
    width: 100%;
    
    top: 60px;
    position: absolute;
    
    overflow: hidden;
    transition: all 1s ease;
    ${props => props.open ? 'max-height: 200px;' :`max-height: 0px;`}

    background: #215379;
    box-shadow: 0px 8px 12px rgba(3, 68, 107, 0.15);
`

export const ContactsWrapper = styled.div`
   width: 100%;
   display: flex;
   flex-direction: column;
   height: 50px;
   justify-content: space-between;
   margin: 2% 0;
`

export const LinkText = styled.span`
   font-weight: normal;
   font-size: 18px;
   line-height: 22px;
   color: #F9F9F9;
   cursor: pointer;
   transition: text-shadow .3s;

   :hover {
      text-shadow: 0 0 .65px #F9F9F9, 0 0 .65px #F9F9F9;
   }
   border-bottom: 1px solid ${props => props.exit ? '#F9F9F9' : 'transparent'};
`

export const ExitButton = styled.button`
   outline: none;
   background-color: transparent;
   border: none;

   margin-left: -5px;
`

export const UserName = styled.span`
   font-weight: normal;
   font-size: 18px;
   line-height: 22px;
   color: #F9F9F9;
   // width: 35%;
   min-height: 50px;
`

export const LoggedName = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: space-around;
`