import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { API_URL } from '../../config'
import { userSelectors } from '../../redux/user/user.selectors'
import {toggleModal} from '../../redux/modals/modals.actions'
import { NominationItem } from './NominationItem/nomination-item.component'


import { Nominations, SectionTitle } from './nominations.styles'
import { NominationVotingModal } from './NominationVoting/nomination-voting.component'

const NominationsSection = () => {
    const jwt = useSelector(userSelectors.jwt)
    const currentUser = useSelector(userSelectors.currentUser)

    const [award_1IsOpen, setAward_1Open] = useState(false)
    const [award_2IsOpen, setAward_2Open] = useState(false)
    const [award_3IsOpen, setAward_3Open] = useState(false)
    const [award_4IsOpen, setAward_4Open] = useState(false)
    const [award_5IsOpen, setAward_5Open] = useState(false)
    const [award_6IsOpen, setAward_6Open] = useState(false)
    const [award_7IsOpen, setAward_7Open] = useState(false)
    const [award_8IsOpen, setAward_8Open] = useState(false)
    const [award_9IsOpen, setAward_9Open] = useState(false)
    const [managers, setManagers] = useState([])
    const [staff, setStaff] = useState([])
    const dispatch = useDispatch()


    const nominationDescriptions = {
        award_1: ['Искусно мотивирует команду и заряжает на успех'],
        award_2: ['Умело комбинирует команды, техники и инструменты для достижения максимального эффекта'],
        award_3: ['Обеспечивает скорость процессов, отвечающую требованиям рынка'],
        award_4: ['Быстро подстраивает команды и процессы под изменившиеся обстоятельства'],
        award_5: ['Поощряет сотрудничество, действует с учётом интересов партнера'],
        award_6: ['Моментально впитывает новые знания и применяет их на практике'],
        award_7: ['Регулярно предлагает новые здравые идеи'],
        award_8: ['Демонстрирует активную жизненную позицию, готовность участвовать в различных задачах'],
        award_9: ['Преодолевает внешние и внутренние препятствия на пути к результату']
    }


    //
    // function getAllCandidates() {
    //     axios({
    //         method: 'get',
    //         headers: {
    //             JWT: jwt,
    //             "Content-Type": 'application/json',
    //             "X-api-token": `${jwt}`
    //         },
    //         url: `${API_URL}/users`
    //     })
    //         .then((response) => {
    //             setCandidates(() => response.data.filter((el) => el.mt !== true && el.user_id !== currentUser.user_info.user_id))
    //         })
    //         .catch(e => {
    //             console.log(e)
    //         })
    // }

    function getAllManagers() {
        axios({
            method: 'get',
            headers: {
                JWT: jwt,
                "Content-Type": 'application/json',
                "X-api-token": `${jwt}`
            },
            url: `${API_URL}/users?managers=true`
        })
            .then((response) => {
                setManagers(() => response.data.filter((el) => el.mt !== true && el.user_id !== currentUser.user_info.user_id))
            })
            .catch(e => {
                console.log(e)
            })
    }

    function getAllStaff() {
        axios({
            method: 'get',
            headers: {
                JWT: jwt,
                "Content-Type": 'application/json',
                "X-api-token": `${jwt}`
            },
            url: `${API_URL}/users?managers=false`
        })
            .then((response) => {
                setStaff(() => response.data.filter((el) => el.mt !== true && el.user_id !== currentUser.user_info.user_id))
            })
            .catch(e => {
                console.log(e)
            })
    }

    useEffect(() => {
        getAllStaff()
        getAllManagers()
    }, [])

    const openFinished = () => {
        dispatch(toggleModal())
    }

    return (
        <Nominations>
            <SectionTitle>НОМИНАЦИИ ДЛЯ МЕНЕДЖЕРОВ 2024</SectionTitle>
            <div>
                <NominationItem id='award_1' text='Вдохновляющий лидер' textEng='Inspire' onOpen={() => setAward_1Open(true)} />
                <NominationVotingModal awardId={1} allCandidates={managers} text='Вдохновляющий лидер' open={award_1IsOpen} onClose={() => setAward_1Open(false)} textEng='Inspire' desc={nominationDescriptions.award_1} />
                <NominationItem id='award_2' text='Знаток интеграции' textEng='Integrate' onOpen={() => setAward_2Open(true)} />
                <NominationVotingModal awardId={2} allCandidates={managers} text='Знаток интеграции' textEng='Integrate' open={award_2IsOpen} onClose={() => setAward_2Open(false)} desc={nominationDescriptions.award_2} />
                <NominationItem id='award_3' text='Мастер ускорения' textEng='Accelerate' onOpen={() => setAward_3Open(true)} />
                <NominationVotingModal awardId={3} allCandidates={managers} text='Мастер ускорения' textEng='Accelerate' open={award_3IsOpen} onClose={() => setAward_3Open(false)} desc={nominationDescriptions.award_3} />
                <NominationItem id='award_4' text='Эксперт адаптации' textEng='Adapt' onOpen={() => setAward_4Open(true)} />
                <NominationVotingModal awardId={4} allCandidates={managers} text='Эксперт адаптации' textEng='Adapt' open={award_4IsOpen} onClose={() => setAward_4Open(false)} desc={nominationDescriptions.award_4} />
            </div>
                <SectionTitle>НОМИНАЦИИ ДЛЯ ИНДИВИДУАЛЬНЫХ СОТРУДНИКОВ 2024</SectionTitle>
            <div>
                <NominationItem id='award_5' text='Надежное партнерство' textEng='Reliable partner' onOpen={() => setAward_5Open(true)} />
                <NominationVotingModal awardId={5} allCandidates={staff} text='Надежное партнерство' textEng='Reliable partner' open={award_5IsOpen} onClose={() => setAward_5Open(false)} desc={nominationDescriptions.award_5} />
                <NominationItem id='award_6' text='Быстрое обучение' textEng='Learning on the fly' onOpen={() => setAward_6Open(true)} />
                <NominationVotingModal awardId={6} allCandidates={staff} text='Быстрое обучение' textEng='Learning on the fly' open={award_6IsOpen} onClose={() => setAward_6Open(false)} desc={nominationDescriptions.award_6} />
                <NominationItem id='award_7' text='Генерация перемен' textEng='Idea hamster' onOpen={() => setAward_7Open(true)} />
                <NominationVotingModal awardId={7} allCandidates={staff} text='Генерация перемен' textEng='Idea hamster' open={award_7IsOpen} onClose={() => setAward_7Open(false)} desc={nominationDescriptions.award_7} />
                <NominationItem id='award_8' text='Регулярная инициатива' textEng='Regular initiative' onOpen={() => setAward_8Open(true)} />
                <NominationVotingModal awardId={8} allCandidates={staff} text='Регулярная инициатива' textEng='Regular initiative' open={award_8IsOpen} onClose={() => setAward_8Open(false)} desc={nominationDescriptions.award_8} />
                <NominationItem id='award_9' text='Настойчивость в достижении цели' textEng='Сommitment to success' onOpen={() => setAward_9Open(true)} />
                <NominationVotingModal awardId={9} allCandidates={staff} text='Настойчивость в достижении цели' textEng='Сommitment to success' open={award_9IsOpen} onClose={() => setAward_9Open(false)} desc={nominationDescriptions.award_9} />
            </div>
        </Nominations>
    )
}

export default NominationsSection