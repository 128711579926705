import styled from 'styled-components'
import { CustomButton } from '../../styles/common'

export const SectionHeadingWrapper = styled.section`
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
   z-index: 10;
   overflow: hidden;
`

export const BlurWrapper = styled.div`
   position: relative;
   z-index: 5;
   background: rgba(19, 56, 84, 0.8);
   filter: blur(110px);
   border-radius: 88px;
`



export const SectionHeadingContainer = styled.div`
   margin: 450px 20px 320px 20px;
   display: flex;
   flex-direction: column;
   align-items: center;
   position: relative;
   z-index: 10;
   text-align: center;



   >h1, h2 {
      font-weight: bold;
      font-size: 70px;
      line-height: 84px;
      color: #F9F9F9;
      text-transform: uppercase;
      margin: 0;

      position: relative;
   z-index: 10;
   }

   >h2 {
      font-size: 24px;
      line-height: 29px;
      margin: 8px 0 35px 0;
   }

   @media(max-width: 768px) {
      margin: 400px 20px 250px 20px;
      >h1, h2 {
         font-size: 60px;
         line-height: 70px;
      }

      >h2 {
         font-size: 22px;
         line-height: 26px;
         margin: 8px 0 35px 0;
      }
   }

   @media(max-width: 600px) {
      margin: 350px 20px 200px 20px;
      >h1, h2 {
         font-size: 50px;
         line-height: 60px;
      }

      >h2 {
         font-size: 18px;
         line-height: 22px;
         margin: 8px 0 35px 0;
      }
   }

   @media(max-width: 500px) {
      /* margin: 20% 20px 20% 20px; */
      margin: 300px 20px 100px 20px;
      >h1, h2 {
         font-size: 40px;
         line-height: 50px;
      }

      >h2 {
         font-size: 14px;
         line-height: 18px;
         margin: 8px 0 35px 0;
      }
   }

   @media(max-width: 400px) {
      margin: 290px 20px 40px 20px;
      >h1, h2 {
         font-size: 36px;
         line-height: 40px;
      }

      >h2 {
         font-size: 14px;
         line-height: 18px;
         margin: 8px 0 35px 0;
      }
   }

   @media(max-width: 370px) {
      margin: 270px 20px 40px 20px;
   }
`
export const CupImage = styled.img`
   /* height: 954px;
    width: 954px; */
   max-width: 800px;
   width: 130%;
   z-index: 0;

   position: absolute;
   /* left: -25%; */
   top: -250%;

   @media(max-width: 500px) {
      top: -250px;
   }
   @media(max-width: 360px) {
      top: -230px;
   }
   @media(max-width: 350px) {
      top: -190px;
   }
`

