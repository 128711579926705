import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ItemContainer, NominationCheck } from './nomination-item.styles'
import { userSelectors } from '../../../redux/user/user.selectors'
import { toggleModal } from '../../../redux/modals/modals.actions'

export const NominationItem = ({ text, textEng, id, onOpen }) => {
    const userData = useSelector(userSelectors.currentUser)
    const isChosen = userData?.vote_info[id] !== 0
    const dispatch = useDispatch()


    const toggleModals = () => {
        dispatch(toggleModal())
    }


    return (
        <ItemContainer onClick={onOpen} disabled={isChosen}>
            <div>
                <svg width="239" height="230" viewBox="0 0 156 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M61.966 2.17318C65.4949 13.3446 67.5212 39.8128 47.3959 56.3149C38.2706 31.9412 53.3071 10.0647 61.966 2.17318Z" fill={isChosen ? '#E1CEA0' : '#F5C142'} />
                    <path d="M93.9976 2.17318C90.4688 13.3446 88.4424 39.8128 108.568 56.3149C117.693 31.9412 102.657 10.0647 93.9976 2.17318Z" fill={isChosen ? '#E1CEA0' : '#F5C142'} />
                    <path d="M25.5536 17.213C34.2039 25.1139 49.2167 47.0067 40.065 71.3705C19.9575 54.8466 22.0126 28.3806 25.5536 17.213Z" fill={isChosen ? '#E1CEA0' : '#F5C142'} />
                    <path d="M130.41 17.213C121.76 25.1139 106.747 47.0067 115.899 71.3705C136.006 54.8466 133.951 28.3806 130.41 17.213Z" fill={isChosen ? '#E1CEA0' : '#F5C142'} />
                    <path d="M5.91331 54.4571C17.3552 56.9743 41.303 68.4276 45.5593 94.1031C19.8838 89.8467 8.43052 65.8989 5.91331 54.4571Z" fill={isChosen ? '#E1CEA0' : '#F5C142'} />
                    <path d="M150.05 54.4571C138.608 56.9743 114.661 68.4276 110.404 94.1031C136.08 89.8467 147.533 65.8989 150.05 54.4571Z" fill={isChosen ? '#E1CEA0' : '#F5C142'} />
                    <path d="M7.50427 95.9419C18.6718 92.401 45.1379 90.3459 61.6618 110.453C37.2979 119.605 15.4052 104.592 7.50427 95.9419Z" fill={isChosen ? '#E1CEA0' : '#F5C142'} />
                    <path d="M148.459 95.9419C137.292 92.401 110.826 90.3459 94.3019 110.453C118.666 119.605 140.558 104.592 148.459 95.9419Z" fill={isChosen ? '#E1CEA0' : '#F5C142'} />
                    <path d="M26.4362 131.812C34.3371 123.162 56.2298 108.149 80.5937 117.301C64.0698 137.408 37.6037 135.353 26.4362 131.812Z" fill={isChosen ? '#E1CEA0' : '#F5C142'} />
                    <path d="M129.623 131.812C121.722 123.162 99.8293 108.149 75.4654 117.301C91.9893 137.408 118.455 135.353 129.623 131.812Z" fill={isChosen ? '#E1CEA0' : '#F5C142'} />
                    <path d="M56.3106 150L51.699 139.563C54.4498 138.673 61.0194 134.951 65.2912 127.184V120.631L78.1553 117.718L91.2621 120.631L91.5048 127.184C95.7766 134.951 102.346 138.673 105.097 139.563L100.485 150C96.4401 148.22 89.5631 144.66 83.9805 135.922C81.9308 132.714 79.3746 129.612 78.398 128.641C77.4214 129.612 74.8652 132.714 72.8155 135.922C67.233 144.66 60.3559 148.22 56.3106 150Z" fill={isChosen ? '#E1CEA0' : '#F5C142'} />
                </svg>
            </div>
            <div>
                <p> {text} </p>
                <p> {textEng} </p>
            </div>
            <NominationCheck>
                {isChosen ?
                    <div>
                        <svg width="55" height="55" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M22 38.5V38.5C12.8865 38.5 5.5 31.1135 5.5 22V22C5.5 12.8865 12.8865 5.5 22 5.5V5.5C31.1135 5.5 38.5 12.8865 38.5 22V22C38.5 31.1135 31.1135 38.5 22 38.5Z" stroke="#F5C142" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M29.3333 18.3333L20.1667 27.4999L14.6667 21.9999" stroke="#F5C142" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    </div>
                    : null
                }
            </NominationCheck>
        </ItemContainer>
    )
}