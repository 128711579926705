import { constants } from './user.constants'

const INITIAL_STATE = {
    currentUser: undefined,
    error: undefined,
    isLoading: false,
    jwt: undefined,
    registerSuccess: false,
    voteError: undefined,
}

export const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case constants.REGISTER_USER_PENDING:
            return {
                ...state,
                isLoading: true,
                error: undefined,
                registerSuccess: false,
            }

        case constants.REGISTER_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                registerSuccess: true
            }

        case constants.REGISTER_USER_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }

        case constants.LOGOUT:
            return INITIAL_STATE

        case constants.LOGIN_USER_PENDING:
            return {
                ...state,
                isLoading: true,
                error: undefined
            }

        case constants.LOGIN_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                voteError: undefined,
                currentUser: action.payload
            }

        case constants.LOGIN_USER_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }

        case constants.SAVE_JWT:
            return {
                ...state,
                jwt: action.payload
            }

        case constants.CLEAR_USER_ERRORS:
            return {
                ...state,
                error: undefined,
                registerSuccess: false
            }

        case constants.CAST_VOTE_PENDING:
            return {
                ...state,
                voteError: undefined,
            }

        case constants.CAST_VOTE_FAILED: 
            return {
                ...state,
                voteError: action.payload
            }

        case constants.CAST_VOTE_SUCCESS:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    vote_info: {
                        ...state.currentUser.vote_info,
                        [action.payload.award]: action.payload.id
                    }
                }
            }

        default: 
            return state

    }
}