import axios from 'axios'

import { constants } from './user.constants'
import { API_URL } from '../../config';

export const registerUserPending = () => ({
    type: constants.REGISTER_USER_PENDING
})

export const registerUserSuccess = () => ({
    type: constants.REGISTER_USER_SUCCESS
})

export const registerUserError = (error) => ({
    type: constants.REGISTER_USER_FAILED,
    payload: error
})

export const registerUser = ({ email, password }) => {
    return (dispatch) => {
        dispatch(registerUserPending())

        axios({
            method: 'post',
            url: `${API_URL}/register`, //TODO: add url
            data: {
                email: email,
                pass: password
            }
        })
            .then((response) => {
                // console.log(response)
                dispatch(registerUserSuccess())
                dispatch(loginUser({ email, password }))
                // console.log('${API_URL}/register: ', response)
            })
            .catch((error) => {
                dispatch(registerUserError(error.response?.data && error.response.data.error ? error.response.data.error : 'Произошла непредвиденная ошибка'))
                console.log(error.response.data)
            })
    }
}


export const loginUserPending = () => ({
    type: constants.LOGIN_USER_PENDING
})

export const loginUserSuccess = (user) => ({
    type: constants.LOGIN_USER_SUCCESS,
    payload: user
})

export const loginUserError = (error) => ({
    type: constants.LOGIN_USER_FAILED,
    payload: error
})

export const saveJWT = (jwt) => ({
    type: constants.SAVE_JWT,
    payload: jwt
})

export const loginUser = ({ email, password }) => {
    return (dispatch) => {
        dispatch(loginUserPending())

        axios({
            method: 'post',
            url: `${API_URL}/auth`, //TODO: add url
            data: {
                email: email,
                pass: password
            }
        })
            .then((response) => {
                const token = response.data.token
                // console.log('${API_URL}/auth: ', response)
                axios({
                    method: 'get',
                    headers: {
                        // JWT: token
                        "Content-Type": 'application/json',
                        "X-api-token": `${token}`
                    },
                    url: `${API_URL}/lk`, //TODO: add url
                })
                    .then((response) => {
                        dispatch(saveJWT(token))
                        dispatch(loginUserSuccess(response.data))
                        // console.log('${API_URL}/lk: ', response)
                    })
            })
            .catch((error) => {
                console.log(error.response.data)
                dispatch(loginUserError(error.response?.data && error.response.data.error ? error.response.data.error : 'Произошла непредвиденная ошибка'))
            })
    }
}

export const clearError = () => ({
    type: constants.CLEAR_USER_ERRORS
})

export const logout = () => ({
    type: constants.LOGOUT
})
export const castVotePending = () => ({
    type: constants.CAST_VOTE_PENDING
})

export const castVoteSuccess = ({ award, id }) => ({
    type: constants.CAST_VOTE_SUCCESS,
    payload: { award, id }
})

export const castVoteFailed = (error) => ({
    type: constants.CAST_VOTE_FAILED,
    payload: error
})

export const clearVoteErrors = () => ({
    type: constants.CLEAR_VOTE_ERRORS
})

export const castVote = ({ award, id, token, reason }) => {
    return (dispatch) => {
        dispatch(castVotePending())
        console.log(API_URL)
        console.log(award, id, token, reason)
        axios({
            method: 'post',
            headers: {
                "X-api-token": `${token}`
            },
            data: {
                reason: reason
            },
            url: `${API_URL}/vote?award=${award}&id=${id}`
        })
            .then((response) => {
                console.log(response)
                dispatch(castVoteSuccess({ award: `award_${award}`, id: id }))
            })
            .catch((e) => {
                dispatch(castVoteFailed(e))
                console.log(e)
            })
    }
}
