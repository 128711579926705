import styled from 'styled-components'

export const AppWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
`

export const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1 0 auto;
  margin-top: 60px;
`