import styled from 'styled-components'
import { CustomButton } from '../../styles/common'

export const AuthWrapper = styled.div`
   width: 100%!important;
   height: 100%;
   transition-duration: 0.5s;
   display: flex;
   flex-direction: column;
   justify-content: center;
`

export const AuthForm = styled.form`

`

export const Button = styled(CustomButton)`
   max-width: 100%;
`

export const InputWrapper = styled.div`
   margin-bottom: 14px;
   display: flex;
   flex-direction: column;

   >label {
      margin-bottom: 10px;

      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #767676;
   }
`

export const InputContainer = styled.div`
   display: flex;
   position: relative;

   >button {
      outline: none;
      border: none;
      background-color: transparent;
      cursor: pointer;
      position: absolute;
      right: 12px;
      top: 6px;
   }

   >img {
      position: absolute;
      right: -30px;
      top: 7px;
      transition-duration: 0.2s;
      visibility: ${props => props.translate === -66.6 ? 'hidden' : 'visible'}
   }
`

export const Input = styled.input`
   width: 100%;
   height: 35px;
   padding:0 12px;

   background: #ECECEC;
   border-radius: 4px;
   position: relative;
   outline: none;
   border: 1px solid transparent;

   font-weight: normal;
   font-size: 16px;
   line-height: 14px;
   color: #215379;
   transition-duration: 0.3s;

   :focus {
      border-color: #215379;
      transition-duration: 0.3s;
   }
`

export const Error = styled.div`
   font-weight: normal;
   font-size: 16px;
   line-height: 20px;
   color: #f00606;
   transition-duration: 0.3s;
   /* text-align: center; */

   margin-top: 20px;
`