import React from 'react'
import ModalBase from '../ModalBase/modal-base.component'
import { InstructionsContent } from './finishedModal.styles'

const FinishedModal = ({ open, onClose }) => {

    return (
        <ModalBase open={open} onClose={onClose} buttonTitle='Понятно, продолжить' >
            <InstructionsContent>
                <span style={{fontSize: 22}}>
                    Голосование завершено!
                </span>
            </InstructionsContent>
        </ModalBase>
    )
}

export default FinishedModal