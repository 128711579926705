import React, { useEffect, useState } from 'react'
import { Formik, useFormikContext } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import {
   AuthWrapper,
   Button,
   Input,
   InputWrapper,
   InputContainer,
   AuthForm,
   Error
} from './auth-modal.styles'
import ModalBase from '../ModalBase/modal-base.component'
import noShowPassIco from '../../assets/no-show-pass.svg'
import showPassIco from '../../assets/show-pass.svg'
import validateTrueIco from '../../assets/validate-true.svg'
import validateFalseIco from '../../assets/validate-false.svg'
import { userSelectors } from '../../redux/user/user.selectors';
import { clearError, loginUser } from '../../redux/user/user.actions';
import { InputWr } from '../RegistrationModal/registration-modal.styles';
import { Checkbox } from '@material-ui/core';

const validationSchema = yup.object().shape({
   email: yup.string()
      .trim()
      .email('Введите корректный email')
      .required('Введите email'),
   password: yup.string()
      .required('Введите пароль')
      .min(4, 'Пароль должен содержать минимум 4 символов'),
   aggree: yup.boolean().isTrue("Обязательное поле")
})



const AuthModal = ({
   openAuth,
   setModalAuthOpen,
}) => {
   const [showPassword, setShowPassword] = useState(false)
   const [defaultStateForm, setDefaultStateForm] = useState(false)
   const [disabledButton, setDisabledutton] = useState(true)

   const currentUser = useSelector(userSelectors.currentUser)
   const dispatch = useDispatch()

   const loginError = useSelector(userSelectors.error)
   const [mainError, setMainError] = useState('')

   const FormikData = () => {
      const { values, errors } = useFormikContext();

      useEffect(() => {
         values.email || values.password ?
            setDefaultStateForm(true) : setDefaultStateForm(false)

         setDisabledutton(handleDisabledButton(defaultStateForm, errors))
      }, [values, errors])
      return null;
   }

   const onCloseModalAuth = () => {
      setModalAuthOpen(false)
   }

   useEffect(() => {
      if (currentUser) {
         onCloseModalAuth()
      }
   }, [currentUser])

   useEffect(() => {
      if (loginError && openAuth) {
         setMainError(loginError)
         dispatch(clearError())
      }
   }, [loginError, openAuth, dispatch])

   const handleSubmit = (values) => {
      const { email, password } = values
      dispatch(loginUser({ email: email.toLowerCase().trim(), password }))
   }

   const handleDisabledButton = (defaultStateForm, errors) => {
      let disabled
      if (defaultStateForm) {
         if (errors.email) {
            return true
         } else {
            disabled = false
         }
         if (errors.password) {
            return true
         } else {
            disabled = false
         }
         if (errors.confPassword) {
            return true
         } else {
            disabled = false
         }
         if (errors.aggree) {
            return true
         } else {
            disabled = false
         }
      } else {
         return true
      }
      return disabled
   }


   return (
      <ModalBase
         open={openAuth}
         onClose={onCloseModalAuth}
         nonButton={true}
      >
         <AuthWrapper>
            <Formik
               initialValues={{ email: '', password: '', aggree: false }}
               onSubmit={values => handleSubmit(values)}
               validationSchema={validationSchema}
               validateOnChange={true}
            >
               {({ handleChange, handleSubmit, values, errors, setFieldValue }) => (
                  <AuthForm onSubmit={handleSubmit}>
                     <InputWrapper>
                        <label>Укажите вашу почту:</label>
                        <InputContainer>
                           <Input
                              onChange={handleChange('email')}
                              value={values.email}
                              type='text'
                              tabIndex="1"
                           />
                           {
                              defaultStateForm &&
                              <img
                                 src={
                                    errors.email ?
                                       validateFalseIco :
                                       validateTrueIco
                                 }
                                 alt='validate icon'
                              />
                           }
                        </InputContainer>
                     </InputWrapper>

                     <InputWrapper>
                        <label>Пароль:</label>
                        <InputContainer >
                           <Input
                              type={showPassword ? 'text' : 'password'}
                              onChange={handleChange('password')}
                              value={values.password}
                              tabIndex="2"
                           />
                           <button
                              type="button"
                              onClick={() => setShowPassword(!showPassword)}
                              tabIndex="-1"
                           >
                              <img
                                 src={
                                    showPassword ?
                                       showPassIco :
                                       noShowPassIco
                                 }
                                 alt='toggle show password'
                              />
                           </button>
                           {
                              defaultStateForm && values.password &&
                              <img
                                 src={
                                    errors.password ?
                                       validateFalseIco :
                                       validateTrueIco
                                 }
                                 alt='validate icon'
                              />
                           }
                        </InputContainer>
                     </InputWrapper>
                     <InputWrapper style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',

                     }}>
                        <InputWr>
                           <Checkbox
                              checked={values.aggree}
                              onChange={(e) => {
                                 setFieldValue('aggree', !values.aggree)
                              }}
                              name="aggree"
                              color="primary"
                           />
                           <label style={{
                              marginBottom: 0
                           }} > Согласен с условиями <a href="/Политика конфиденциальности.pdf" download rel="noopener noreferrer" target="_blank">политики конфиденциальности</a>, <a href="/Обработка-персональных данных.pdf" download rel="noopener noreferrer" target="_blank">условиями обработки персональных данных</a> и <a href="/Сookie.pdf" download rel="noopener noreferrer" target="_blank">политикой обработки файлов cookie</a></label>
                           {
                              defaultStateForm &&
                              <img
                                 src={
                                    errors.aggree ?
                                       validateFalseIco :
                                       validateTrueIco
                                 }
                                 alt='validate icon'
                              />
                           }
                        </InputWr>
                     </InputWrapper>
                     <Button
                        type='submit'
                        disabled={disabledButton}
                        // onClick={() => setTranslate(-66.6)}
                        tabIndex="3"
                     >
                        Авторизация
                     </Button>
                     {/* {
                        values.email || values.password ?
                           setDefaultStateForm(true) : setDefaultStateForm(false)
                     }
                     {
                        setDisabledutton(handleDisabledButton(defaultStateForm, errors))
                     } */}
                     <Error>{mainError && mainError}</Error>
                     <FormikData />
                  </AuthForm>
               )}
            </Formik>
         </AuthWrapper>
      </ModalBase>
   )
}

export default AuthModal