import styled from 'styled-components'

export const FooterWrapper = styled.footer`
   width: 100%;
   height: 120px;
   background-color: #051C2E;
   padding: 0 24px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   flex: 0 0 auto;
`

export const Logo = styled.img`
   width: 165px;
`

export const ContactsWrapper = styled.div`
   /* width: 100%;
   max-width: 550px; */
   display: flex;
   align-items: center;
   /* justify-content: space-between; */
   @media(max-width: 768px){
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
   }
`

export const LinkText = styled.span`
   font-weight: normal;
   font-size: 16px;
   line-height: 20px;
   color: #F9F9F9;
   cursor: pointer;
   transition: text-shadow .3s;
   margin: ${props => props.indents ? props.indents : '0'};

   :hover {
      text-shadow: 0 0 .65px #F9F9F9, 0 0 .65px #F9F9F9;
   }
`