import styled from "styled-components";

export const InstructionsContent = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;


    >span{
        margin-bottom: 24px;
        text-align: center;
    }

    >ul{
        padding-left: 0px;
        margin-left: 0px;
    }

    >ul>li{
        list-style: disc;
    }
`