import React, { forwardRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { styled } from '@material-ui/core/styles';

import { CloseButton, ModalContainer, CustomDialog } from './modal-base.styles';
import CloseSVG from '../../assets/close.svg'
import { CustomButton } from '../../styles/common';

const Transition = forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const ModalBase = ({
   open,
   onClose,
   children,
   onClickClose = onClose,
   buttonDisabled = false,
   onButtonClick = onClose,
   nonButton = false,
   background = false,
}) => {
   return (
      <CustomDialog
         open={open}
         TransitionComponent={Transition}
         disableScrollLock
         id='modal-base'
         scroll='body'
         // maxWidth={true}
         keepMounted
         onClose={onClose}
         aria-labelledby="alert-dialog-slide-title"
         aria-describedby="alert-dialog-slide-description"
      >
         <ModalContainer>
            {
               background ?
                  <svg width="231" height="400" viewBox="0 0 231 400" fill="black" xmlns="http://www.w3.org/2000/svg">
                     <path d="M41.7531 -8.39355C31.0254 25.5674 24.8652 106.031 86.0463 156.197C113.787 82.1011 68.0761 15.5967 41.7531 -8.39355Z" fill="#F5C142" fillOpacity="0.1" />
                     <path d="M152.447 37.3276C126.15 61.3464 80.5112 127.9 108.332 201.966C169.459 151.734 163.212 71.277 152.447 37.3276Z" fill="#F5C142" fillOpacity="0.1" />
                     <path d="M212.153 150.549C177.37 158.202 104.568 193.02 91.629 271.073C169.683 258.134 204.501 185.333 212.153 150.549Z" fill="#F5C142" fillOpacity="0.1" />
                     <path d="M207.317 276.663C173.367 265.898 92.9104 259.651 42.6777 320.778C116.744 348.599 183.298 302.96 207.317 276.663Z" fill="#F5C142" fillOpacity="0.1" />
                     <path d="M-163.634 385.71C-139.615 359.413 -73.061 313.774 1.0051 341.595C-49.2275 402.722 -129.684 396.474 -163.634 385.71Z" fill="#F5C142" fillOpacity="0.1" />
                     <path d="M150.054 385.71C126.035 359.413 59.481 313.774 -14.5851 341.595C35.6476 402.722 116.104 396.474 150.054 385.71Z" fill="#F5C142" fillOpacity="0.1" />
                     <path d="M-72.8157 441L-86.8351 409.272C-78.4726 406.567 -58.5011 395.253 -45.5147 371.641V351.719L-6.4079 342.864L33.4368 351.719L34.1746 371.641C47.161 395.253 67.1326 406.567 75.495 409.272L61.4756 441C49.1779 435.589 28.2717 424.767 11.3008 398.204C5.06979 388.451 -2.70127 379.02 -5.67004 376.068C-8.6388 379.02 -16.4099 388.451 -22.6409 398.204C-39.6118 424.767 -60.5179 435.589 -72.8157 441Z" fill="#F5C142" fillOpacity="0.1" />
                  </svg>
                  : null
            }
            <CloseButton tabindex="-1" onClick={onClickClose}>
               <img src={CloseSVG} alt='Закрыть' />
            </CloseButton>
            {
               open && children
            }
            {/* {
               !nonButton &&
               <CustomButton
                  disabled={buttonDisabled}
                  onClick={onButtonClick}
               >
                  {buttonTitle}
               </CustomButton>
            } */}
         </ModalContainer>
      </CustomDialog>
   );
}

export default ModalBase;
