import styled from 'styled-components'

export const MainWrapper = styled.main`
   width: 100%;
   height: 100%;
   min-height: 100vh;
`

export const CupImage = styled.img`
   /* height: 954px;
   width: 954px; */
   /* max-width: 900px; */
   width: 50%;
   z-index: 0;

   position: absolute;
   left: -25%;
   top: 10%;

   @media(max-width: 1020px){
      height: 754px;
      width: 754px;

      left: -377px;
      top: -5%;
   }

   @media(max-width: 765px){
      height: 554px;
      width: 554px;

      left: -277px;
      top: 0;
   }

   @media(max-width: 565px){
      height: 454px;
      width: 454px;

      left: -227px;
      top: 5%;
   }

   @media(max-width: 385px){
      height: 354px;
      width: 354px;

      left: -177px;
      top: 10%;
   }


`