import React, { useState } from 'react'

import {
   FooterWrapper,
   Logo,
   ContactsWrapper,
   LinkText
} from './footer.styles'
import logoSVG from '../../assets/logo.svg'
import InstructionsModal from '../InstructionsModal/instructions-modal.component'
import {useWindowDimensions} from "../../hooks/dimensions";

const Footer = () => {
   const [isModalInsOpen, setModalInsOpen] = useState(false)
    const {width} = useWindowDimensions()

   return (
      <FooterWrapper>
         <Logo src={logoSVG} alt='logo' />

         <ContactsWrapper>
             {
                 width>768 ?
                     <LinkText onClick={() => setModalInsOpen(true)}>
                         Правила голосования
                     </LinkText> : null
             }
            <a href='mailto:2024vote-online.ru'>
               <LinkText indents={width > 768 ? '0 30px 0 30px' : undefined}>
                  support@2024vote-online.ru
               </LinkText>
            </a>
            <a href='tel:+79995357879'>
               <LinkText>
                  +7 999 535 78 79
               </LinkText>
            </a>
         </ContactsWrapper>
         <InstructionsModal
            open={isModalInsOpen}
            onClose={() => setModalInsOpen(false)}
         />
      </FooterWrapper>
   )
}

export default Footer