import React, { useState } from 'react'
import AuthModal from '../AuthModal/auth-modal.component'
import InstructionsModal from '../InstructionsModal/instructions-modal.component'
import RegistrationModal from '../RegistrationModal/registration-modal.component'
import { useSelector, useDispatch } from 'react-redux';

import {
   SectionHeadingWrapper,
   SectionHeadingContainer,
   ButtonsWrapper,
   Button,
   SectionTitle,
   // CupImage
} from './section-heading.styles'
import { userSelectors } from '../../redux/user/user.selectors';
import Cup from '../../assets/cup.png'
import { toggleModal } from '../../redux/modals/modals.actions';

const SectionHeading = () => {
   const [isModalRegOpen, setModalRegOpen] = useState(false)
   const [isModalInsOpen, setModalInsOpen] = useState(false)
   const [isModalAuthOpen, setModalAuthOpen] = useState(false)
   const currentUser = useSelector(userSelectors.currentUser)
   const dispatch = useDispatch()


   return (
      <SectionHeadingWrapper>
         <SectionHeadingContainer>
            <h1>Amgen Россия</h1>
            <h2>программа награждения сотрудников</h2>
            {
               !currentUser &&
               <ButtonsWrapper>
                  <Button onClick={() => setModalRegOpen(true)}>
                     Регистрация
               </Button>
                  <Button auth onClick={() => setModalAuthOpen(true)}>
                     Авторизация
               </Button>
               </ButtonsWrapper>
            }
         </SectionHeadingContainer>
         {/* <CupImage src={Cup} /> */}
         <SectionTitle>НОМИНАЦИИ 2024</SectionTitle>
         <RegistrationModal
            open={isModalRegOpen}
            setModalRegOpen={setModalRegOpen}
            isModalInsOpen={isModalInsOpen}
            setModalInsOpen={setModalInsOpen}
         />
         <AuthModal
            openAuth={isModalAuthOpen}
            setModalAuthOpen={setModalAuthOpen}
         />
         <InstructionsModal
            open={isModalInsOpen}
            onClose={() => setModalInsOpen(false)}
         />
      </SectionHeadingWrapper>
   )
}

export default SectionHeading