import styled from "styled-components";

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    height: 230px;
    width: 200px;
    margin: 10px 0px;
    /* border-radius: 6px;
    border: 1px solid transparent; */
    position: relative;

    font-weight: 700;
    color: #F9F9F9;
    font-size: 16px;


    >img{
        /* margin-bottom: 14px; */
        height: 62%;
    }

    >div{
        height: 23%;
        margin-top: 15%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    }
`

export const AwardsContainer = styled.section`
    display: flex;
    flex-wrap: wrap;

    width: 100%;
    justify-content: center;
    align-items: center;

    margin-bottom: 150px;
`

export const AwardsContainerManagers = styled.section`
    display: flex;
    flex-wrap: wrap;

    width: 100%;
    justify-content: center;
    align-items: center;

    margin-bottom: 150px;
`