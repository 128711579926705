import styled from "styled-components";

export const ContentWrapper = styled.div`
    width: calc(300% + 300px)!important;
    height: 100%;

    transition-duration: 0.5s;
    display: flex;
    justify-content: space-between;
    transform: translateX(${props => props.translate ? props.translate : '0'}%);
    align-self: flex-start;

    >div{
        width: 50%;
        min-height: 100%;
        margin-right: 101px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

    >div{
        display: flex;
        width: 100%;
        >span{
            margin-bottom: 10px;
        }
        :first-of-type{
            flex-direction: column;
        }
        
    }
} 

`

export const NominationTitle = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60px;

    align-items: center;
    justify-content: space-between;

    text-align: center;
    color: #133854;
    font-size: 20px;
    font-weight: 700;
    text-align: center;

`

export const NominationDescription = styled.div`
    background-color: rgba(19,56,84,0.1);
    border-radius: 8px;
    border: 1px solid transparent;
    padding: 7px;
    margin-top: 20px;

    >ul{

        >li{
            list-style: disc;
            color: #215379;
            >span{
                color: #767676;
            }
        }
    }
`

export const CandidateInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 110px;
    align-items: center;
    justify-content: space-between;
`

export const CandidateInput = styled.input`
    width: 100%;

    background-color: #ECECEC;
    border: 1px solid transparent;
    border-radius: 6px;

    height: 40px;
    padding-left: 5px;

    color: #215379;
    font-weight: 400;
    font-size: 16px;

    transition-duration: 0.3s;

    :focus{
        outline: none;
        border: 1px solid #215379;
        transition-duration: 0.3s;

        border-bottom-left-radius: ${props => props.listVisible ? '0px' : '6px'};
        border-bottom-right-radius: ${props => props.listVisible ? '0px' : '6px'};
    }
`

export const ReasonInput = styled.textarea`
    height: 70px;
    width: 100%;
    resize: none;
    padding: 3px 5px;
    font-family: "Myriad Pro", sans-serif;
    
    color: #215379;
    font-weight: 400;
    font-size: 16px;
    background-color: #ECECEC;
    border: 1px solid transparent;
    border-radius: 6px;

    transition-duration: 0.3s;

    :focus{
        outline: none;
        border: 1px solid #215379;
        transition-duration: 0.3s;

        border-bottom-left-radius: ${props => props.listVisible ? '0px' : '6px'};
        border-bottom-right-radius: ${props => props.listVisible ? '0px' : '6px'};
    }
`

export const SuggestionsList = styled.ul`
    position: absolute;
    top: 61%;
    width: 100%;

    @media(max-width: 680px) {
        top: 62%;
   }

    @media(max-width: 640px) {
        top: 62%;
   }
   @media(max-width: 575px) {
      top: 63.5%;
      /* width: 21.35%; */
   }
   @media(max-width: 490px) {
      top: 63%;
      /* width: 23.08%; */
   }
   @media(max-width: 434px){
      top: 63%;
      /* width: 22.25%; */
   }
   @media(max-width: 399px) {
      top: 65.5%;
      /* width: 21%; */
   }

    padding-left: 0;
    border: 1px solid rgb(33,83,121);
    border-radius: 6px;
    border-top: 0px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    z-index: 30;

    background-color: #ECECEC;

    li{
        list-style: none;
        transition-duration: 0.3s;
        
        border-top: 1px solid rgba(33,83,121, 0.2);

        >button{
            outline: none;
            border: 0;
            background-color: #ECECEC;
            width: 100%;
            height: 100%;
            padding: 5px;
            text-align: left;

            color: #215379;
            font-weight: 400;
            line-height: 14px;
            border-radius: 6px;

            :hover{
            background-color: rgba(33,83,121,0.1);
            transition-duration: 0.3s;
            cursor: pointer;            
            }
            :focus {
                background-color: rgba(33,83,121,0.1);
                transition-duration: 0.3s;
            }
            
        }
    }

`

export const FirstFrame = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    >div:last-of-type{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        >span:first-of-type{
            margin: 10% 53% 10px 0px;
        }
        >span:nth-of-type(3){
            width: 90%;
            margin-bottom: 10px;
        }
    }

    >span{
        color: #133854;
        font-size: 20px;
        font-weight: 700;
        text-align: center;

        :first-of-type{
            margin-top: 10%;
        }
        
    }

    >button{
        width: 100%;
    }
    
`

export const SecondFrame = styled.div`
    margin: 3% 0;

    >div{
        
        >span{
            text-align: center;
    }
    }

    >span{
        color: #133854;
        font-size: 20px;
        font-weight: 700;
        text-align: center;

        :first-of-type{
            margin-top: 20%;
        }
    }
`

export const ThirdFrame = styled.div`
    width: 100%;
    display: flex;
    margin: 3% 0;
    
    

    >span{
        color: #133854;
        font-size: 20px;
        font-weight: 700;
        text-align: center;

        :first-of-type{
            margin-top: 20%;
        }

        :last-of-type{
            font-size: 12px;
            font-weight: 400;
            color: #767676;
        }
    }

    >button{
        width: 100%;
        height: 40px;
        margin: 0px 6%;

        border-radius: 10px;
        border: 1px solid transparent;
        outline: none;

        transition-duration: 0.3s;

        color: #133854;
        background-color: #e0dfdf;

        :hover{
            cursor: pointer;
            transition-duration: 0.3s;
            background-color: #c4c2c2;
        }
    }

`

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 10%;
    
    >button{

        width: 50%;
        height: 40px;
        margin: 0px 6%;

        border-radius: 10px;
        border: 1px solid transparent;
        outline: none;

        transition-duration: 0.3s;

        :last-of-type{
            background-color: #DDA261;
            color: #ECECEC;

            :hover{
                background-color: #b48049;
            }

        }

        :hover{
            cursor: pointer;
            transition-duration: 0.3s;
            background-color: #cfcccc;
        }
    }
`

export const InvalidName = styled.span`
    opacity: ${props => props.showError ? '1' : '0'};
    transition-duration: 0.3s;
    color: #e46e6e;

`

export const NoReason = styled.span`
    opacity: ${props => props.showErrorReason ? '1' : '0'};
    transition-duration: 0.3s;
    color: #e46e6e;

`