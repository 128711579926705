import styled from 'styled-components'

export const HeaderWrapper = styled.header`
   width: 100%;
   height: 60px;
   padding: 0 24px;
   background: #215379;
   box-shadow: 0px 8px 12px rgba(3, 68, 107, 0.15);

   display: flex;
   align-items: center;
   justify-content: center;
   position: fixed;
   z-index: 100;
`

export const Logo = styled.img`
   position: absolute;
   left: 24px;
`

export const NavWrapper = styled.nav`
   width: 100%;
   /* max-width: 380px; */
   display: flex;
   align-items: center;
   justify-content: center;
   // margin: 0 25px;
   @media(max-width: 1060px){
    margin-right: 280px;
   }
   
`

export const ContactsWrapper = styled.div`

   display: flex;
   align-items: center;
   /* justify-content: space-between; */
   position: absolute;
   right: 24px;
`

export const LinkText = styled.span`
   font-weight: normal;
   font-size: 16px;
   line-height: 20px;
   color: #F9F9F9;
   cursor: pointer;
   transition: text-shadow .3s;
   border-bottom: 1px solid ${props => props.exit ? '#F9F9F9' : 'transparent'};

   margin: ${props => props.indents ? props.indents : '0'};
   :hover {
      text-shadow: 0 0 .65px #F9F9F9, 0 0 .65px #F9F9F9;
   }
`

export const ExitButton = styled.button`
   outline: none;
   background-color: transparent;
   border: none;

   margin-left: 25px;
`

export const UserName = styled.span`
   font-weight: normal;
   font-size: 18px;
   line-height: 22px;
   color: #F9F9F9;
`