const INITIAL_STATE = {
    open: false
}

export const  modalsReducer =  (state=INITIAL_STATE, action) => {
    switch(action.type){
        case 'TOGGLE_MODAL':
            return {
                open: !state.open
            }
        default:
            return state
    }
}