import React from 'react'
import { useSelector, useDispatch } from 'react-redux';

import {
   HeaderWrapper,
   Logo,
   NavWrapper,
   ContactsWrapper,
   LinkText,
   ExitButton,
   UserName
} from './header.styles'
import logoSVG from '../../assets/logo.svg'
import { userSelectors } from '../../redux/user/user.selectors';
import { logout } from '../../redux/user/user.actions';

const Header = () => {
   const currentUser = useSelector(userSelectors.currentUser)
   const dispatch = useDispatch()

   return (
      <HeaderWrapper>
         <Logo src={logoSVG} alt='logo' />

         {
            currentUser &&
            <NavWrapper>
               <UserName>
                  {currentUser.user_info.email}
               </UserName>
               <ExitButton onClick={() => dispatch(logout())}>
                  <LinkText exit>
                     Выход
                  </LinkText>
               </ExitButton>
            </NavWrapper>
         }

         <ContactsWrapper>
            <a href='mailto:support@2024vote-online.ru'>
               <LinkText indents='0 30px 0 0'>
                  support@2024vote-online.ru
               </LinkText>
            </a>
            <a href='tel:+79995357879'>
               <LinkText>
                  +7 999 535 78 79
               </LinkText>
            </a>
         </ContactsWrapper>
      </HeaderWrapper >
   )
}

export default Header