import styled from "styled-components";

export const ItemContainer = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    border: 0px;

    border-radius: 6px;
    border: 1px solid transparent;

    margin: 10px;

    position: relative;

    outline: none;
    transition-duration: 0.3s;
    cursor: pointer;

    padding: 2% 0;

    :hover{
        transition-duration: 0.3s;
        background-color: rgba(255, 255, 255, 0.3);
    }

    :disabled{
        cursor: default;
        background-color: rgba(255, 255, 255, 0);
    }

    height: 366px;
    width: 313px;

    @media(max-width: 768px){
        margin: 10px 20px;
        width: 166px;
        height: 263px;
            >div>svg{
            width: 140px;
            height: 134px;
        }
    }

    >div{
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: ${props => props.disabled ? '#A9A9A9' : '#F9F9F9'};

        :first-of-type{
            height: 68%;
            margin-bottom: 7%;
        }        
        :nth-of-type(2){
            display: flex;
            flex-direction: column;
            height: 33%;
            justify-content: space-between;
        }
    }
`

export const NominationCheck = styled.div`
    position: absolute;
    top: 33%;

    @media(max-width: 768px){
        top: 22%;
    }

    >div{
    @media(max-width: 768px){
        >svg{
            width: 44px;
            height: 44px;
        }
    }
    }

    
`