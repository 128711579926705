import React from 'react'

import { CupImage, MainWrapper } from './main-after-auth.styles'
import Cup from '../../assets/cup.png'
import SectionHeading from '../../components/SectionHeading/section-heading.component'
import NominationsSection from '../../components/Nominations/nominations.component'
import SectionHeadingAA from '../../components/SectionHeadingAA/section-heading-a-a.component'

const MainAfterAuth = () => {

   return (
      <MainWrapper>
         {/* <CupImage src={Cup} />
         <SectionHeading />
         <NominationsSection /> */}
         <SectionHeadingAA />
         <NominationsSection />
      </MainWrapper>
   )
}

export default MainAfterAuth;