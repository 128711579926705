import React, { useEffect, useRef, useState } from 'react'
import { Formik, useFormikContext } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import {
   RegistrationWrapper,
   InstructionsContainer,
   RegistrationContainer,
   ResultContainer,
   Button,
   Input,
   InputWrapper,
   InputContainer,
   RegForm,
   Error,
   InputWr
} from './registration-modal.styles'
import ModalBase from '../ModalBase/modal-base.component'
import noShowPassIco from '../../assets/no-show-pass.svg'
import showPassIco from '../../assets/show-pass.svg'
import validateTrueIco from '../../assets/validate-true.svg'
import validateFalseIco from '../../assets/validate-false.svg'
import { userSelectors } from '../../redux/user/user.selectors';
import { clearError, registerUser } from '../../redux/user/user.actions';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const validationSchema = yup.object().shape({
   email: yup.string()
      .trim()
      .email('Введите корректный email')
      .required('Введите email'),
   password: yup.string()
      .required('Введите пароль')
      .min(4, 'Пароль должен содержать минимум 4 символов'),
   confPassword: yup.string()
      .oneOf([yup.ref('password')], 'Пароли должны совпадать')
      .required('Подтвердите пароль')
      .min(4, 'Пароль должен содержать минимум 4 символов'),
   aggree:yup.boolean().isTrue("Обязательное поле")
})


const RegistrationModal = ({
   open,
   setModalRegOpen,
   // isModalInsOpen,
   setModalInsOpen
}) => {
   const [translate, setTranslate] = useState(0)
   const [showPassword, setShowPassword] = useState(false)
   const [defaultStateForm, setDefaultStateForm] = useState(true)
   const [disabledButton, setDisabledutton] = useState(true)
   const firstInputRef = useRef()
   const [formikAction, setFormikAction] = useState()

   // const [isSubmitting, setIsSubmitting] = useState(false);
   // const jwt = useSelector(userSelectors.jwt)
   // const registrationSuccess = useSelector(userSelectors.registerSuccess)

   const currentUser = useSelector(userSelectors.currentUser)
   const registrationError = useSelector(userSelectors.error)
   const [mainError, setMainError] = useState('')
   const dispatch = useDispatch()



   const FormikData = () => {
      const { values, errors } = useFormikContext();

      useEffect(() => {
         values.email || values.password || values.confPassword ?
            setDefaultStateForm(true) : setDefaultStateForm(false)

         setDisabledutton(handleDisabledButton(defaultStateForm, errors))
      }, [values, errors])
      return null;
   }

   const onCloseModalReg = () => {
      setModalRegOpen(false)
      setTranslate(0)
   }

   useEffect(() => {
      if (registrationError && open) {
         setMainError(registrationError)
         dispatch(clearError())
      }
   }, [registrationError, open, dispatch])


   useEffect(() => {
      if (currentUser) {
         setTranslate(-66.6)
         setMainError('')
         if (formikAction) {
            formikAction.resetForm()
         }
      }
   }, [currentUser])

   const handleSubmit = (values, action) => {
      const { email, password } = values
      // action.resetForm()
      setFormikAction(action)
      dispatch(registerUser({ email: email.toLowerCase().trim(), password }))
   }

   const handleDisabledButton = (defaultStateForm, errors) => {
      let disabled
      if (defaultStateForm) {
         if (errors.email) {
            return true
         } else {
            disabled = false
         }
         if (errors.password) {
            return true
         } else {
            disabled = false
         }
         if (errors.confPassword) {
            return true
         } else {
            disabled = false
         }
         if (errors.aggree) {
            return true
         } else {
            disabled = false
         }

      } else {
         return true
      }
      return disabled
   }

   const handleChangeModal = () => {
      onCloseModalReg()
      setTimeout(() => {
         setModalInsOpen(true)
      }, 500);
   }

   const handleTabulation = (e) => {
      if (e.keyCode === 9) {
         e.preventDefault()
         e.stopPropagation()
         firstInputRef.current?.focus()
      }

   }

   return (
      <ModalBase
         open={open}
         onClose={onCloseModalReg}
         nonButton={true}
      >
         <RegistrationWrapper translate={translate}>
            <InstructionsContainer>
               <div>
                  <span>
                     Инструкция к голосованию:
                  </span>
                  <ul>
                     <li>
                        Регистрация возможна только с корпоративной почты Amgen.
                     </li>
                     <li>
                        После успешной регистрации вам предстоит проголосовать в девяти номинациях.
                     </li>
                     <li>
                        В четырех номинациях для менеджеров вы можете предложить любого руководителя компании. В пяти номинациях для сотрудников вы можете выбрать кандидата из числа ваших коллег, за исключением руководителей.
                     </li>
                     <li>
                        Нажав курсором на номинацию, введите имя и фамилию кандидата. При вводе первых символов имени и фамилии сайт предложит выбрать из подходящих вариантов, необходимо обязательно выбрать кандидата из списка.
                     </li>
                     <li>
                        После успешного голосования по данной номинации система сделает ее не активной для повторного выбора.
                     </li>
                  </ul>
               </div>
               <Button
                  onClick={() => setTranslate(-33.3)}
                  tabIndex="-1"
               >
                  Понятно, продолжить
               </Button>
            </InstructionsContainer>

            <RegistrationContainer>
               <Formik
                  initialValues={{ email: '', password: '', confPassword: '', aggree: false }}
                  onSubmit={(values, action) => handleSubmit(values, action)}
                  validationSchema={validationSchema}
                  validateOnChange={true}
               >
                  {({ handleChange, handleSubmit, values, errors, setFieldValue }) => {
                     console.log(values)
                     return (
                        <RegForm onSubmit={handleSubmit}>
                           <InputWrapper>
                              <label>Укажите вашу почту:</label>
                              <InputContainer translate={translate}>
                                 <Input
                                    onChange={handleChange('email')}
                                    value={values.email}
                                    type='text'
                                    tabIndex="1"
                                    ref={firstInputRef}
                                 />
                                 {
                                    defaultStateForm &&
                                    <img
                                       src={
                                          errors.email ?
                                             validateFalseIco :
                                             validateTrueIco
                                       }
                                       alt='validate icon'
                                    />
                                 }
                              </InputContainer>
                           </InputWrapper>

                           <InputWrapper>
                              <label>Пароль:</label>
                              <InputContainer translate={translate}>
                                 <Input
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={handleChange('password')}
                                    value={values.password}
                                    tabIndex="2"
                                 />
                                 <button
                                    type="button"
                                    onClick={() => setShowPassword(!showPassword)}
                                    tabIndex="-1"
                                 >
                                    <img
                                       src={
                                          showPassword ?
                                             showPassIco :
                                             noShowPassIco
                                       }
                                       alt='toggle show password'
                                    />
                                 </button>
                                 {
                                    defaultStateForm && values.password &&
                                    <img
                                       src={
                                          errors.password ?
                                             validateFalseIco :
                                             validateTrueIco
                                       }
                                       alt='validate icon'
                                    />
                                 }
                              </InputContainer>
                           </InputWrapper>

                           <InputWrapper>
                              <label>Повторите пароль:</label>
                              <InputContainer translate={translate}>
                                 <Input
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={handleChange('confPassword')}
                                    value={values.confPassword}
                                    tabIndex="3"
                                    onKeyDown={handleTabulation}
                                 />
                                 <button
                                    type="button"
                                    onClick={() => setShowPassword(!showPassword)}
                                    tabIndex="-1"
                                 >
                                    <img
                                       src={
                                          showPassword ?
                                             showPassIco :
                                             noShowPassIco
                                       }
                                       alt='toggle show password'
                                    />
                                 </button>
                                 {
                                    defaultStateForm && values.confPassword &&
                                    <img
                                       src={
                                          errors.confPassword ?
                                             validateFalseIco :
                                             validateTrueIco
                                       }
                                       alt='validate icon'
                                    />
                                 }
                              </InputContainer>
                           </InputWrapper>
                           <InputWrapper style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',

                           }}>
                              <InputWr>
                                 <Checkbox
                                    checked={values.aggree}
                                    onChange={(e) => {
                                       setFieldValue('aggree', !values.aggree)
                                    }}
                                    name="aggree"
                                    color="primary"
                                 />
                                 <label style={{
                                    marginBottom: 0
                                 }} > Согласен с условиями <a href="/Политика конфиденциальности.pdf" download rel="noopener noreferrer" target="_blank">политики конфиденциальности</a>, <a href="/Обработка-персональных данных.pdf" download rel="noopener noreferrer" target="_blank">условиями обработки персональных данных</a> и <a href="/Сookie.pdf" download rel="noopener noreferrer" target="_blank">политикой обработки файлов cookie</a></label>
                                 {
                                    defaultStateForm &&
                                    <img
                                       src={
                                          errors.aggree ?
                                             validateFalseIco :
                                             validateTrueIco
                                       }
                                       alt='validate icon'
                                    />
                                 }
                              </InputWr>
                           </InputWrapper>

                           <Button
                              type='submit'
                              disabled={disabledButton}
                              // disabled={false}
                              tabIndex="4"
                           >
                              Регистрация
                           </Button>
                           {/* {
                           values.email || values.password || values.confPassword ?
                              setDefaultStateForm(true) : setDefaultStateForm(false)
                        }
                        {
                           setDisabledutton(handleDisabledButton(defaultStateForm, errors))
                        } */}
                           <Error>{mainError && mainError}</Error>
                           <FormikData />
                        </RegForm>
                     )
                  }}
               </Formik>
            </RegistrationContainer>

            <ResultContainer tabIndex={-1}>
               <span>
                  Поздравляем, <br />
                  регистрация прошла успешно.
               </span>
               <Button
                  goToVoite
                  tabIndex="-1"
                  aria-hidden="true"
                  onClick={handleChangeModal}
               >Перейти к правилам голосования</Button>
               <span>ИЛИ</span>
               <Button
                  onClick={onCloseModalReg}
                  tabIndex="-1"
                  aria-hidden="true"
               >Перейти к голосованию</Button>
            </ResultContainer>
         </RegistrationWrapper>
      </ModalBase>
   )
}

export default RegistrationModal