import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { AppContainer, AppWrapper } from './App.styles.jsx';
import Footer from './components/Footer/footer.component.jsx';
import Header from './components/Header/header.component.jsx';
import HeaderMobile from './components/HeaderMobile/header-mobile.component.jsx';
import Main from './pages/Main/main.component.jsx';
import { userSelectors } from './redux/user/user.selectors';
import MainAfterAuth from './pages/MainAfterAuth/main-after-auth.component.jsx';
import { useWindowDimensions } from './hooks/dimensions'
import FinishedModal from './components/FinishedModal/FinishedModal.component';
import { modalSelectors } from './redux/modals/modals.selectors.jsx';
import { toggleModal } from './redux/modals/modals.actions.jsx';
import CookieConsent from 'react-cookie-consent-notification'

function App() {
  const location = useLocation()
  const currentUser = useSelector(userSelectors.currentUser)
  // const error = useSelector(userSelectors.error)
  const { width } = useWindowDimensions()
  const open = useSelector(modalSelectors.open)
  const dispatch = useDispatch()

  // useEffect(() => {
  //   if (error) {
  //     console.log('error: ', error)
  //   }
  // }, [error])

  return (
    <AppWrapper>
      {
        width > 768 ?
          <Header /> : <HeaderMobile />
      }
      {/*<Header />*/}
      {/*<HeaderMobile />*/}
      <AppContainer>
        <Switch location={location} key={location.pathname}>
          <Route exact path='/' component={currentUser ? MainAfterAuth : Main} />
        </Switch>
        <FinishedModal
         open={open}
         onClose={() => dispatch(toggleModal())}
        />
      </AppContainer>
      <Footer />
      <CookieConsent
        background={'#242738'}
        bottomPosition={true}
        buttonText={'OK'}
        buttonBackground={'#DDA261'}
        buttonColor={'#fff'}
        
        color={'#fff'}
        
        padding={20}
      >
        Данный сайт не собирает cookie файлы
      </CookieConsent>
    </AppWrapper>
  );
}

export default App;
