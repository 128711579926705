export const constants = {
    LOGIN_USER: 'LOGIN_USER',
    LOGIN_USER_PENDING: 'LOGIN_USER_PENDING',
    LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
    LOGIN_USER_FAILED: 'LOGIN_USER_FAILED',

    REGISTER_USER: 'REGISTER_USER',
    REGISTER_USER_PENDING: 'REGISTER_USER_PENDING',
    REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',
    REGISTER_USER_FAILED: 'REGISTER_USER_FAILED',

    LOGOUT: 'LOGOUT',
    CLEAR_USER_ERRORS: 'CLEAR_USER_ERRORS',

    SAVE_JWT: 'SAVE_JWT',

    CAST_VOTE: 'CAST_VOTE',
    CAST_VOTE_PENDING: 'CAST_VOTE_PENDING',
    CAST_VOTE_SUCCESS: 'CAST_VOTE_SUCCESS',
    CAST_VOTE_FAILED: 'CAST_VOTE_FAILED',

    CLEAR_VOTE_ERRORS: 'CLEAR_VOTE_ERRORS',
}