import styled from 'styled-components'
import { CustomButton } from '../../styles/common'

export const RegistrationWrapper = styled.div`
   width: calc(300% + 300px)!important;
   height: 100%;
   transition-duration: 0.5s;
   display: flex;
   justify-content: space-between;
   transform: translateX(${props => props.translate ? props.translate : '0'}%);
   align-self: flex-start;

   >div {
      width: 33.3%;
      min-height: 100%;
      margin-right: 101px;
   }
`

export const InstructionsContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
   padding-top: 20px;

   >div>span{
      margin-left: 33%;
      margin-bottom: 24px;
   }
   >div{
    height: 80%;
    width: 100%;
    margin-left: -30px;
   }

   >ul{
      margin-left: 0px;
   }

   >ul>li{
      list-style: disc;
   }
`

export const RegistrationContainer = styled.div`
   padding-top: 20px;
`

export const RegForm = styled.form`

`

export const ResultContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;

   >span {
      font-weight: normal;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      color: #215379;

      margin-bottom: 15px;

      :last-of-type {
         margin-bottom: 0;
         margin-top: 20px;
      }
   }
`

export const Button = styled(CustomButton)`
   max-width: 100%;
   ${props => !props.goToVoite ? '' :
      `
         border-color: #DDA261;
         color: #DDA261;
         background-color: transparent;
         opacity: 1;

         :hover {
            background-color: transparent;
            opacity: 0.7;
         }
      `
   }
`

export const InputWrapper = styled.div`
   margin-bottom: 14px;
   display: flex;
   flex-direction: column;

   >label {
      margin-bottom: 10px;

      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #767676;

      cursor: pointer;
      >a {
         text-decoration: underline;
         
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #767676;
      }
   }
`

export const InputContainer = styled.div`
   display: flex;
   position: relative;

   >button {
      outline: none;
      border: none;
      background-color: transparent;
      cursor: pointer;
      position: absolute;
      right: 12px;
      top: 6px;
   }

   >img {
      position: absolute;
      right: -30px;
      top: 7px;
      transition-duration: 0.2s;
      visibility: ${props => props.translate === -66.6 ? 'hidden' : 'visible'}
   }
`


export const InputWr = styled.div`
display: flex;
align-items: center;
width: 100%;
position: relative;
>label {
      margin-bottom: 10px;

      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #767676;

      cursor: pointer;
      >a {
         text-decoration: underline;
         
      font-weight: normal;
      
      line-height: 16px;
      color: #767676;
      }
   }
   >img {
      position: absolute;
      right: -30px;
   
      transition-duration: 0.2s;
      visibility: ${props => props.translate === -66.6 ? 'hidden' : 'visible'}
   }

`

export const Input = styled.input`
   width: 100%;
   height: 35px;
   padding:0 12px;

   background: #ECECEC;
   border-radius: 4px;
   position: relative;
   outline: none;
   border: 1px solid transparent;

   font-weight: normal;
   font-size: 16px;
   line-height: 14px;
   color: #215379;
   transition-duration: 0.3s;

   :focus {
      border-color: #215379;
      transition-duration: 0.3s;
   }
`

export const Error = styled.div`
   font-weight: normal;
   font-size: 16px;
   line-height: 20px;
   color: #f00606;
   transition-duration: 0.3s;
   /* text-align: center; */

   margin-top: 20px;
`