import styled from 'styled-components';

export const CustomButton = styled.button`
    height: 50px;
    width: 100%;
    max-width: 260px;
    margin-top: 20px;

    border-radius: 8px;
    border: solid 1px transparent;
    background-color: #DDA261;
    outline: none;
    box-shadow: 0px 6px 12px rgba(3, 68, 107, 0.3);

    color: #F9F9F9;
    font-size: 14px;
    font-weight: 600;

    transition-duration: 0.3s;
    cursor: pointer;

    :hover{
        background-color: #c48d54;
        transition-duration: 0.3s;
    }

    :disabled {
        background-color: #767676;
        :hover{
        background-color: #767676;
        }
    }


`