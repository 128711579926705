import React from 'react'
import {
   SectionHeadingWrapper,
   SectionHeadingContainer,
   CupImage,
   BlurWrapper,
} from './section-heading-a-a.styles'
import Cup from '../../assets/cup.png'

const SectionHeadingAA = () => {

   return (
      <SectionHeadingWrapper>

         {/* <BlurWrapper> */}
         <SectionHeadingContainer>
            <h1>Amgen Россия</h1>
            <h2>программа награждения сотрудников</h2>
            <CupImage src={Cup} />
         </SectionHeadingContainer>
         {/* </BlurWrapper> */}

      </SectionHeadingWrapper>
   )
}

export default SectionHeadingAA