import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CustomButton } from '../../../styles/common'
import { castVote } from '../../../redux/user/user.actions'
import { userSelectors } from '../../../redux/user/user.selectors'

import ModalBase from '../../ModalBase/modal-base.component'
import {
    ButtonWrapper,
    CandidateInput,
    ContentWrapper,
    FirstFrame,
    SuggestionsList,
    SecondFrame,
    ThirdFrame,
    InvalidName,
    ReasonInput,
    NoReason,
    NominationTitle,
    NominationDescription,
} from './nomination-voting.styles'

export const NominationVotingModal = ({ open, onClose, text, textEng, allCandidates, awardId, desc }) => {
    const [candidates, setCandidates] = useState([])
    const [translate, setTranslate] = useState(0)
    const [showSuggestions, setShowSuggestions] = useState(false)
    const [showError, setShowError] = useState(false)
    const [showErrorReason, setShowErrorReason] = useState(false)
    const [reason, setReason] = useState('')
    const [chosenCandidate, setChosenCandidate] = useState({})
    const [candidateName, setCandidateName] = useState('')
    const [langIsEng, setLangIsEng] = useState(false)
    const focusRef = useRef()
    const listRef = useRef()
    const dispatch = useDispatch()
    const token = useSelector(userSelectors.jwt)
    let refs = useRef({}).current
    const [currentFocusedSuggestion, setCurrentFocusedSuggestion] = useState()
    const error = useSelector(userSelectors.voteError)

    const useOnClickOutside = (ref, handler) => {
        useEffect(() => {
            const listener = event => {
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };
            document.addEventListener('mousedown', listener);
            return () => {
                document.removeEventListener('mousedown', listener);
            };
        }, [ref, handler]);
    };

    useOnClickOutside(listRef, () => setShowSuggestions(false))
    const english = /^[A-Za-z0-9 ]*$/

    function handleChange(text) {
        if(!text.endsWith(' ')) setLangIsEng(english.test(text))
        let name = text.toLowerCase().split(' ')
        let suggestions = []

        if (allCandidates !== undefined) {
            for (let i = 0; i < allCandidates.length; i++) {
                if (name.length === 1) {
                    if ((allCandidates[i].first_name.toLowerCase()).startsWith(name[0]) || (allCandidates[i].last_name.toLowerCase()).startsWith(name[0])
                    || (allCandidates[i].first_name_eng.toLowerCase()).startsWith(name[0]) || (allCandidates[i].last_name_eng.toLowerCase()).startsWith(name[0])) {
                        suggestions.push(allCandidates[i])
                    }
                } else if (name.length === 2) {
                    if ((allCandidates[i].first_name.toLowerCase() === name[0] && (allCandidates[i].last_name.toLowerCase()).startsWith(name[1]))
                    || (allCandidates[i].first_name_eng.toLowerCase() === name[0] && (allCandidates[i].last_name_eng.toLowerCase()).startsWith(name[1]))) {
                        suggestions.push(allCandidates[i])
                    }
                }
                if (suggestions.length === 5) break
            }
        }

        setCandidates(suggestions)

        setShowError(false)
        setCandidateName(text)
        if (text.length > 3 && candidates.length > 0) {
            setShowSuggestions(true)
        } else setShowSuggestions(false)

        // if(name.length === 2){
        //     setChosenCandidate(
        //         () => allCandidates.find((el) =>
        //             (el.first_name.toLowerCase() === name[0] && el.last_name.toLowerCase() === name[1])
        //             || (el.first_name_eng.toLowerCase() === name[0] && el.last_name_eng.toLowerCase() === name[1])
        //         )
        //     )
        // }
    }

    function handleChoose(item) {
        let name = langIsEng ? (item.first_name_eng + ' ' + item.last_name_eng) : (item.first_name + ' ' + item.last_name)
        setCandidateName(name)
        setChosenCandidate(item)
        setShowSuggestions(false)
    }

    function confirmCandidate() {
        if (candidates.includes(chosenCandidate)) {
            if(reason.trim() === ''){
                setShowErrorReason(true)
            } else
                setTranslate(-33.3)
        }
        else setShowError(true)
    }

    const handleSuggestionsNavigation = (e) => {
        
        if (showSuggestions&&e.keyCode===40) {
            e.preventDefault()
            e.stopPropagation()
            
            if (!currentFocusedSuggestion&&currentFocusedSuggestion!==0) {
                try {
                    setCurrentFocusedSuggestion(0)
                    return;
                } catch {
                    return;
                }
            }
            if (currentFocusedSuggestion!==candidates.length-1) {
                setCurrentFocusedSuggestion(c => c+1)

            }
        }
        if (showSuggestions&&e.keyCode===38) {
            e.preventDefault()
            e.stopPropagation()
            if (!currentFocusedSuggestion) {
                focusRef.current?.focus()
                setCurrentFocusedSuggestion(undefined)
                return;
            } else {
                setCurrentFocusedSuggestion(c => c-1)
            }

        }
    }

    

    useEffect(() => {
        if (currentFocusedSuggestion||currentFocusedSuggestion===0) {
            
            try {
                refs[currentFocusedSuggestion].current?.focus()
            } catch {

            }
        }
    }, [currentFocusedSuggestion])

    return (
        <ModalBase open={open} onClose={() => {
            onClose()
            setTranslate(0)
        }} background>
            <ContentWrapper translate={translate} >
                <div>
                    <FirstFrame>
                        <NominationTitle>
                            <span> {text} </span>
                            <span> {textEng} </span>
                        </NominationTitle>
                        <NominationDescription>
                        <ul>
                        {
                            desc.map((item) => {
                                return <li><span>{item}</span></li>
                            })
                        }
                        </ul>
                        </NominationDescription>
                        <div>
                            <span>Имя и фамилия кандидата:</span>
                            <CandidateInput
                                value={candidateName}
                                listVisible={showSuggestions}
                                ref={focusRef}
                                onChange={(event) => handleChange(event.target.value)}
                                onKeyDown={handleSuggestionsNavigation}
                                onFocus={ () => setCurrentFocusedSuggestion(undefined)}
                            />
                            {
                                showSuggestions ?
                                    <SuggestionsList ref={listRef} id='candidates_suggestions'>
                                        {candidates.map((item, index) => {
                                            const ref = React.createRef()
                                            if (index===0) {
                                                refs = {}
                                            }
                                            refs[`${index}`] = ref
                                            
                                            return <li key={index.toString()}>
                                                <button 
                                                onClick={() => handleChoose(item)} 
                                                ref={ref}
                                                onKeyDown={handleSuggestionsNavigation}
                                                >
                                                    {
                                                        langIsEng ?
                                                            `${item.first_name_eng} ${item.last_name_eng}`
                                                            : `${item.first_name} ${item.last_name}`
                                                    }
                                                </button>
                                            </li>
                                        })}
                                    </SuggestionsList>
                                    : <div />
                            }
                            <InvalidName showError={showError} >Кандидат с таким именем не найден</InvalidName>
                            <span>Обоснуйте свой выбор в нескольких предложениях</span>
                            <ReasonInput
                            onFocus={() => setShowErrorReason(false)}
                            value={reason}
                            onChange={(event) => setReason(event.target.value)}
                            />
                            <NoReason showErrorReason={showErrorReason}>Поле обязательно для заполнения</NoReason>
                        </div>
                        <CustomButton onClick={confirmCandidate}>Проголосовать</CustomButton>
                    </FirstFrame >
                </div>
                <SecondFrame>
                    <span> {text} </span>
                    <span> {textEng} </span>
                    <div>
                        <span>{`Ваш выбор: ${langIsEng ? chosenCandidate?.first_name_eng : chosenCandidate?.first_name} ${langIsEng ? chosenCandidate?.last_name_eng : chosenCandidate?.last_name}! Вы готовы подтвердить свой выбор?`}</span>
                    </div>
                    <ButtonWrapper>
                        <button onClick={() => setTranslate(0)} >Нет</button>
                        <button onClick={() => {
                            setTranslate(-66.6)
                            // dispatch(castVote({award: awardId, id: 11, token: 1}))
                            dispatch(castVote({ award: awardId, id: chosenCandidate.user_id, token: token, reason: reason }))
                        }} >Да</button>
                    </ButtonWrapper>
                </SecondFrame>
                <ThirdFrame>
                    <span> {text} </span>
                    <span> {textEng} </span>
                    <span>{error === undefined ? 'Спасибо, ваш голос за кандидата учтен' : 'Что-то пошло не так при отправке голоса. Пожалуйста, повторите попытку'}</span>
                    <button onClick={() => {
                        error === undefined ? onClose() : setTranslate(0)
                    }}>{error === undefined ? 'Закрыть окно' : 'Назад'}</button>
                </ThirdFrame>
            </ContentWrapper>
        </ModalBase>
    )
}