import styled from 'styled-components'
import { CustomButton } from '../../styles/common'

export const SectionHeadingWrapper = styled.section`
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   position: relative;
   z-index: 10;
`

export const SectionHeadingContainer = styled.div`
   margin: 20% 20px 20% 18%;
   display: flex;
   flex-direction: column;
   align-items: center;
   position: relative;
   z-index: 10;

   >h1, h2 {
      font-weight: bold;
      font-size: 70px;
      line-height: 84px;
      color: #F9F9F9;
      text-transform: uppercase;
      margin: 0;
      z-index: 10;
      text-align: center;
   }

   >h2 {
      font-size: 24px;
      line-height: 29px;
      margin: 8px 0 35px 0;
   }

   @media(max-width: 768px) {
      margin: 20% 20px 20% 20px;
      >h1, h2 {
         font-size: 60px;
         line-height: 70px;
      }

      >h2 {
         font-size: 22px;
         line-height: 26px;
         margin: 8px 0 35px 0;
      }
   }

   @media(max-width: 600px) {
      /* margin: 20% 20px 20% 20px; */
      >h1, h2 {
         font-size: 50px;
         line-height: 60px;
      }

      >h2 {
         font-size: 18px;
         line-height: 22px;
         margin: 8px 0 35px 0;
      }
   }

   @media(max-width: 500px) {
      /* margin: 20% 20px 20% 20px; */
      >h1, h2 {
         font-size: 40px;
         line-height: 50px;
      }

      >h2 {
         font-size: 14px;
         line-height: 18px;
         margin: 8px 0 35px 0;
      }
   }

   @media(max-width: 400px) {
      /* margin: 20% 20px 20% 20px; */
      >h1, h2 {
         font-size: 36px;
         line-height: 40px;
      }

      >h2 {
         font-size: 14px;
         line-height: 18px;
         margin: 8px 0 35px 0;
      }
   }
`

export const ButtonsWrapper = styled.div`
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   z-index: 10;

   @media(max-width: 500px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
   }
`

export const Button = styled(CustomButton)`
   margin: 0;
   ${props => props.auth ? `
      background-color: transparent;
      border-color: #F9F9F9;
      opacity: 1;
      margin-left: 20px;
      :hover {
         background-color: transparent;
         opacity: 0.6;
      }
      @media(max-width: 500px) {
         margin-left: 0;
         margin-top: 20px;
      }
   ` : ''}

`

export const SectionTitle = styled.span`
   font-weight: 700;
   font-size: 40px;
   line-height: 70px;
   color: #F9F9F9;

   margin-bottom: 100px;

   @media(max-width: 500px) {
      font-size: 36px;
      line-height: 40px;

      margin-top: 160px;
      margin-bottom: 130px;
   }

   @media(max-width: 400px) {
      font-size: 30px;
      line-height: 34px;

      margin-top: 160px;
      margin-bottom: 130px;
   }
`


// export const CupImage = styled.img`
//     /* height: 954px; */
//     /* max-width: 900px; */
//    /* width: 55%; */
//    max-width: 900px;
//    z-index: 0;

//    position: absolute;
//    left: -25%;
//    top: -2%;

//    /* @media(max-width: 1020px){
//       height: 754px;
//       width: 754px;

//       left: -377px;
//       top: -5%;
//    }

//    @media(max-width: 765px){
//       height: 554px;
//       width: 554px;

//       left: -277px;
//       top: 0;
//    }

//    @media(max-width: 565px){
//       height: 454px;
//       width: 454px;

//       left: -227px;
//       top: 5%;
//    }

//    @media(max-width: 385px){
//       height: 354px;
//       width: 354px;

//       left: -177px;
//       top: 10%;
//    }*/
// `