import React from 'react'

import WreathSVG from '../../assets/wreath.svg'
import { AwardsContainer, AwardsContainerManagers, ItemContainer } from './section-award-start.styles'

const NominationItem = ({text, textEng}) => {

    return (
        <ItemContainer>
            <img alt='NominationWreath' src={WreathSVG} />
            <div>
                <p> {text} </p>
                <p> {textEng} </p>
            </div>
        </ItemContainer>
    )
} 

export const SectionAwardStart = () => {

    return (
        <AwardsContainer>
            <NominationItem text='Надежное партнерство' textEng='Reliable partner' />
            <NominationItem text='Быстрое обучение' textEng='Learning on the fly' />
            <NominationItem text='Генерация перемен' textEng='Idea hamster' />
            <NominationItem text='Регулярная инициатива' textEng='Regular initiative' />
            <NominationItem text='Настойчивость в достижении цели' textEng='Сommitment to success' />
        </AwardsContainer>
    )
}

export const SectionAwardStartManagers = () => {

    return (
        <AwardsContainerManagers>
            <NominationItem text='Вдохновляющий лидер' textEng='Inspire' />
            <NominationItem text='Знаток интеграции' textEng='Integrate' />
            <NominationItem text='Мастер ускорения' textEng='Accelerate' />
            <NominationItem text='Эксперт адаптации' textEng='Adapt' />
        </AwardsContainerManagers>
)
}

// export default SectionAwardStart