import styled from "styled-components";

export const Nominations = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;

    margin-bottom: 150px;

    >div{
        max-width: 1100px;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        justify-content: center;

    }
`

export const SectionTitle = styled.span`
    font-weight: 700;
    font-size: 40px;
    line-height: 70px;
    color: #F9F9F9;

    margin-bottom: 100px;

    @media(max-width: 500px) {
        font-size: 36px;
        line-height: 40px;

        margin-top: 130px;
        margin-bottom: 130px;
    }

    @media(max-width: 400px) {
        font-size: 30px;
        line-height: 34px;

        margin-top: 130px;
        margin-bottom: 130px;
    }
`