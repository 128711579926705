import { Dialog } from '@material-ui/core';
import styled from 'styled-components';

export const CustomDialog = styled(Dialog)`
   width: 100%;
   height: 100%;
   margin-left: -10px;

`

export const ModalContainer = styled.div`
   width: 600px;
   min-height: 450px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   background: #FBFBFB;
   border: 1px solid #E5E5E5;
   box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.25);
   border-radius: 16px;
   position: relative;
   padding: 50px 100px 70px 100px;
   overflow-x: hidden;
   z-index: 1;

   >svg{
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
   }

   >div {
      width: 100%;
      height: 100%;
      z-index: 2;

      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #767676;
   }
   @media(max-width: 680px) {
      width: 500px;
   }
   @media(max-width: 640px) {
      padding: 50px 100px 70px 100px;
   }
   @media(max-width: 575px) {
      width: 450px;
   }
   @media(max-width: 490px) {
      width: 400px;
      padding: 60px 30px;
   }
   @media(max-width: 434px){
      width: 350px;
   }
   @media(max-width: 399px) {
      width: 300px;
   }
`

export const Button = styled.button`
   margin-top: 60px;
   display: ${props => props.buttonNone ? 'none' : 'block'};
`

export const CloseButton = styled.button`
   position: absolute;
   top: 15px;
   right: 15px;
   outline: none;
   background: transparent;
   border: none;
   cursor: pointer;
   width: 30px;
   height: 30px;
   padding: 0;
   z-index: 10000;
   >img {
      width: 30px;
   }
`