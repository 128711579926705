import React from 'react'
import ModalBase from '../ModalBase/modal-base.component'
import { InstructionsContent } from './instructions-modal.styles'

const InstructionsModal = ({ open, onClose }) => {

    return (
        <ModalBase open={open} onClose={onClose} buttonTitle='Понятно, продолжить' >
            <InstructionsContent>
                <span>
                    Инструкция к голосованию:
                </span>
                <ul>
                    <li>
                        Регистрация возможна только с корпоративной почты Amgen.
                    </li>
                    <li>
                        После успешной регистрации вам предстоит проголосовать в девяти номинациях.
                    </li>
                    <li>
                        В четырех номинациях для менеджеров вы можете предложить любого руководителя компании. В пяти номинациях для сотрудников вы можете выбрать кандидата из числа ваших коллег, за исключением руководителей.
                    </li>
                    <li>
                        Нажав курсором на номинацию, введите имя и фамилию кандидата. При вводе первых символов имени и фамилии сайт предложит выбрать из подходящих вариантов, необходимо обязательно выбрать кандидата из списка.
                    </li>
                    <li>
                        После успешного голосования по данной номинации система сделает ее не активной для повторного выбора.
                    </li>
                </ul>
            </InstructionsContent>
        </ModalBase>
    )
}

export default InstructionsModal
